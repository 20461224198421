// Subscriber Management

import { useState } from "react";
import { useEffect } from "react";
import { SessionManager } from "../utils/consts";
import {
  Button,
  Container,
  Paper,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { TableContainer } from "@mui/material";
import { Table } from "@mui/material";

interface Subscriber {
  email: string;
  country: string;
  industry: string;
}

interface SubscriberManagementProps {
  sessionManager: SessionManager;
}

export interface Features {
  features: string[];
}

export const SubscriberManagement: React.FC<SubscriberManagementProps> = ({
  sessionManager,
}) => {
  const [subscribers, setSubscribers] = useState<Subscriber[]>([]);

  useEffect(() => {
    sessionManager.getData("subscribers").then((data) => {
      setSubscribers(data);
    });
  }, []);

  return (
    <div>
      <Typography variant="h4" gutterBottom>
        Subscriber Management
      </Typography>
      <Container>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell
                  sx={{ fontWeight: "bold", backgroundColor: "#f0f0f0" }}
                >
                  Email
                </TableCell>
                <TableCell
                  sx={{ fontWeight: "bold", backgroundColor: "#f0f0f0" }}
                >
                  Country
                </TableCell>
                <TableCell
                  sx={{ fontWeight: "bold", backgroundColor: "#f0f0f0" }}
                >
                  Industry
                </TableCell>
                <TableCell
                  sx={{ fontWeight: "bold", backgroundColor: "#f0f0f0" }}
                >
                  Actions
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {subscribers.map((subscriber) => (
                <TableRow key={subscriber.email}>
                  <TableCell>{subscriber.email}</TableCell>
                  <TableCell>{subscriber.country}</TableCell>
                  <TableCell>{subscriber.industry}</TableCell>
                  <TableCell>
                    <Button variant="contained" color="primary">
                      Copy
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Container>
    </div>
  );
};
