import React, { useEffect, useState, useRef } from 'react';
import {
  Container,
  Typography,
  AppBar,
  Toolbar,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from '@mui/material';

import SaveIcon from '@mui/icons-material/Save';
import AddIcon from '@mui/icons-material/Add';

// gate icon from @mui

import { API_BASE_URL, SessionManager } from '../../utils/consts';

import { Feature, FeatureCollection, Polygon, Position } from 'geojson';
import L, { LatLng, map } from 'leaflet';
import { EditControl } from 'react-leaflet-draw';
import { Site } from './FarmSites';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PersonIcon from '@mui/icons-material/Person';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import CircleIcon from '@mui/icons-material/Circle';
import { Paper, Grid, Box, Divider, Chip } from '@mui/material';
import Checklist from './Checklist';

interface BMP {
  id: number;
  bmp_type: string;
  status: string;
}

const planTypes = [
  'Feedlot EAD',
  'Poultry EAD',
  'Pork EAD',
  'Dairy EAD',
  'Cattle EAD',
];

interface BMPManagerProps {
  sessionManager: SessionManager;
  site: Site;
  exitAndRefresh: () => void;
}

const toPosition = (latLng: L.LatLng): Position => {
  return [latLng.lng, latLng.lat];
};

const BMPManager: React.FC<BMPManagerProps> = ({
  sessionManager,
  site,
  exitAndRefresh,
}) => {
  const [openNewPlanDialog, setOpenNewPlanDialog] = useState(false);
  const [newPlanType, setNewPlanType] = useState('');
  const [plans, setPlans] = useState<BMP[]>([
    {
      id: 2,
      bmp_type: 'Hygeine Control Plan',
      status: 'In Progress',
    },
  ]);

  const saveAndExit = () => {
    exitAndRefresh();
  };

  return (
    <div>
      <Container>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: '20px',
          }}
        >
          <Typography variant="h4" sx={{ fontWeight: 500 }}>
            Biological Management Plans
          </Typography>
          <Button
            variant="contained"
            startIcon={<AddIcon />}
            onClick={() => setOpenNewPlanDialog(true)}
          >
            New Plan
          </Button>
        </div>

        <Paper elevation={3} sx={{ p: 3, mb: 4, borderRadius: 2 }}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <Box sx={{ mb: 2 }}>
                <Typography variant="h5" sx={{ fontWeight: 500, mb: 1 }}>
                  {site.name}
                </Typography>
                <Chip
                  icon={<CircleIcon sx={{ fontSize: 12 }} />}
                  label={site.status}
                  color={
                    site.status.toLowerCase() === 'active'
                      ? 'success'
                      : 'default'
                  }
                  size="small"
                  sx={{ mt: 0.5 }}
                />
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center', mb: 1.5 }}>
                <LocationOnIcon sx={{ mr: 1, color: 'primary.main' }} />
                <Typography variant="body1">{site.address}</Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box
                sx={{
                  bgcolor: 'grey.50',
                  p: 2,
                  borderRadius: 1,
                  border: '1px solid',
                  borderColor: 'grey.200',
                }}
              >
                <Typography
                  variant="subtitle2"
                  sx={{ mb: 2, color: 'text.secondary' }}
                >
                  Site Manager Details
                </Typography>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                  <PersonIcon
                    sx={{ mr: 1, color: 'primary.main', fontSize: 20 }}
                  />
                  <Typography variant="body2">{site.site_manager}</Typography>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                  <EmailIcon
                    sx={{ mr: 1, color: 'primary.main', fontSize: 20 }}
                  />
                  <Typography variant="body2">
                    {site.site_manager_email}
                  </Typography>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <PhoneIcon
                    sx={{ mr: 1, color: 'primary.main', fontSize: 20 }}
                  />
                  <Typography variant="body2">
                    {site.site_manager_phone}
                  </Typography>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Paper>

        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Type</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {plans.map(plan => (
                <TableRow key={plan.id}>
                  <TableCell>{plan.bmp_type}</TableCell>
                  <TableCell>{plan.status}</TableCell>
                  {/* Assuming status is always 'Active' for now */}
                  <TableCell>
                    <Button
                      variant="outlined"
                      onClick={() => console.log(plan)}
                    >
                      Edit
                    </Button>
                    <span style={{ marginRight: '8px' }}></span>
                    <Button
                      variant="outlined"
                      onClick={() => console.log(plan)}
                    >
                      View
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Container>

      <Dialog
        open={openNewPlanDialog}
        onClose={() => setOpenNewPlanDialog(false)}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>Create New Management Plan</DialogTitle>
        <DialogContent>
          <div style={{ marginBottom: '20px' }}></div>
          <FormControl fullWidth>
            <InputLabel>Plan Type</InputLabel>
            <Select
              value={newPlanType}
              onChange={e => setNewPlanType(e.target.value)}
              label="Plan Type"
            >
              {planTypes.map(type => (
                <MenuItem key={type} value={type}>
                  {type}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {newPlanType.length > 0 && (
            <Checklist sessionManager={sessionManager} />
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenNewPlanDialog(false)}>Cancel</Button>
          <Button
            onClick={() => {
              // TODO: Implement plan creation logic
              setPlans([
                ...plans,
                {
                  id: plans.length + 1,
                  bmp_type: newPlanType,
                  status: 'In Progress',
                },
              ]);
              setOpenNewPlanDialog(false);
              setNewPlanType('');
            }}
          >
            Create
          </Button>
        </DialogActions>
      </Dialog>

      <AppBar
        position="fixed"
        color="primary"
        sx={{
          top: 'auto',
          bottom: 0,
        }}
        style={{ width: '100%' }}
      >
        <Toolbar style={{ width: '100%', left: '240px' }}>
          <Container
            maxWidth="md"
            sx={{ display: 'flex', justifyContent: 'flex-end' }}
          >
            <Button
              variant="contained"
              sx={{ backgroundColor: 'white', color: 'black' }}
              onClick={saveAndExit}
              startIcon={<SaveIcon />}
              style={{ marginRight: '10px' }}
            >
              Save and Exit
            </Button>
          </Container>
        </Toolbar>
      </AppBar>
    </div>
  );
};

export default BMPManager;
