import React, { useEffect, useState } from 'react';
import {
  Container,
  Typography,
  Card,
  CardContent,
  CardHeader,
  Select,
  MenuItem,
  Box,
  TextField,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  AppBar,
  Toolbar,
  CircularProgress,
} from '@mui/material';
import { KeyRounded, HelpOutline, ExpandMore, Edit } from '@mui/icons-material';
import { SessionManager } from '../utils/consts';

export interface ProfileDataProps {
  email: string;
  id: number;
  name: string;
  phone_number: string;
  address: string;
  location: string;
  user_type: string;
}

interface ProfileProps {
  sessionManager: SessionManager;
}

const Profile: React.FC<ProfileProps> = ({ sessionManager }) => {
  const [profile, setProfile] = useState<ProfileDataProps | null>(null);
  const [loading, setLoading] = useState(true);
  const [openDialog, setOpenDialog] = useState(false);

  const getProfile = async () => {
    try {
      const responseData = await sessionManager.getData('profile');
      console.log(responseData);
      setProfile(responseData);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getProfile();
  }, []);

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  return (
    <Container style={{ width: '100%' }}>
      <Container maxWidth="md">
        {loading ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', py: 4 }}>
            <CircularProgress />
          </Box>
        ) : (
          <>
            <Card elevation={0} sx={{ boxShadow: 'none' }}>
              <CardHeader
                sx={{ px: 0, textAlign: 'left' }}
                title={
                  <Typography
                    variant="h6"
                    sx={{
                      color: 'primary.main',
                      borderBottom: 3,
                      borderColor: 'primary.main',
                      pb: 1,
                      textAlign: 'left',
                      fontWeight: 'bold',
                    }}
                  >
                    USER DETAILS
                  </Typography>
                }
              />
              <CardContent sx={{ px: 0, '& > *': { mb: 4 } }}>
                {/* Roles Section */}
                <Box>
                  <Typography
                    variant="subtitle2"
                    gutterBottom
                    sx={{ textAlign: 'left', fontWeight: 'bold' }}
                  >
                    Role
                  </Typography>
                  <Box
                    sx={{
                      bgcolor: 'grey.50',
                      p: 2,
                      borderRadius: 1,
                      display: 'flex',
                      alignItems: 'center',
                      gap: 1,
                    }}
                  >
                    <Typography>{profile?.user_type}</Typography>
                  </Box>
                </Box>

                {/* Full Name Section */}
                <Box>
                  <Typography
                    variant="subtitle2"
                    gutterBottom
                    sx={{ textAlign: 'left', fontWeight: 'bold' }}
                  >
                    Full name
                    <Box component="span" sx={{ color: 'error.main', ml: 0.5 }}>
                      *
                    </Box>
                  </Typography>
                  <Box
                    sx={{
                      bgcolor: 'grey.50',
                      p: 2,
                      borderRadius: 1,
                      display: 'flex',
                      alignItems: 'center',
                      gap: 1,
                    }}
                  >
                    <Box
                      sx={{
                        width: 24,
                        height: 24,
                        bgcolor: 'grey.300',
                        borderRadius: '50%',
                      }}
                    />
                    <Typography>{profile?.name}</Typography>
                  </Box>
                </Box>

                {/* Phone Section */}
                <Box>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: 0.5,
                      mb: 1,
                    }}
                  >
                    <Typography
                      variant="subtitle2"
                      sx={{ textAlign: 'left', fontWeight: 'bold' }}
                    >
                      Mobile phone
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      bgcolor: 'grey.50',
                      p: 2,
                      borderRadius: 1,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                    }}
                  >
                    <Typography>{profile?.phone_number}</Typography>
                  </Box>
                </Box>
                {/* Email Section */}
                <Box>
                  <Typography
                    variant="subtitle2"
                    gutterBottom
                    sx={{ textAlign: 'left', fontWeight: 'bold' }}
                  >
                    Email
                  </Typography>
                  <Box
                    sx={{
                      bgcolor: 'grey.50',
                      p: 2,
                      borderRadius: 1,
                      display: 'flex',
                      alignItems: 'center',
                      gap: 1,
                    }}
                  >
                    <Typography>{profile?.email}</Typography>
                  </Box>
                </Box>
              </CardContent>
            </Card>
            <Card elevation={0} sx={{ boxShadow: 'none' }}>
              <CardHeader
                sx={{ px: 0, textAlign: 'left' }}
                title={
                  <Typography
                    variant="h6"
                    sx={{
                      color: 'primary.main',
                      borderBottom: 3,
                      borderColor: 'primary.main',
                      pb: 1,
                      textAlign: 'left',
                      fontWeight: 'bold',
                    }}
                  >
                    SITES
                  </Typography>
                }
              />
              <CardContent sx={{ px: 0, '& > *': { mb: 4 } }}></CardContent>
            </Card>
          </>
        )}
      </Container>

      {/* Add Footer AppBar */}
      <AppBar
        position="fixed"
        color="primary"
        sx={{
          top: 'auto',
          bottom: 0,
        }}
        style={{ width: '100%' }}
      >
        <Toolbar style={{ width: '100%', left: '240px' }}>
          <Container
            maxWidth="md"
            sx={{ display: 'flex', justifyContent: 'flex-end' }}
          >
            <Button
              variant="contained"
              sx={{
                backgroundColor: 'white',
                color: 'black',
                '&:hover': {
                  backgroundColor: '#f5f5f5',
                },
              }}
              startIcon={<Edit />}
              onClick={handleOpenDialog}
            >
              Edit Profile
            </Button>
          </Container>
        </Toolbar>
      </AppBar>

      {/* Add Edit Dialog */}
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Edit Profile</DialogTitle>
        <DialogContent>
          <Typography>This function is not yet implemented.</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Cancel</Button>
          <Button variant="contained" onClick={handleCloseDialog}>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default Profile;
