import axios from "axios";
import Map from "./Map";
import { Feature, FeatureCollection, Position } from "geojson";
import { useEffect, useState } from "react";
import { API_BASE_URL, SessionManager } from "../../utils/consts";
import {
  Button,
  CircularProgress,
  FormControlLabel,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { centroid, polygon, center } from "@turf/turf";

type CattleDashboardData = {
  paddocks: FeatureCollection;
  feedlots: FeatureCollection;
};

interface CattleDashboardProps {
  sessionManager: SessionManager;
}

const CattleDashboard: React.FC<CattleDashboardProps> = ({
  sessionManager,
}) => {
  const [data, setData] = useState<CattleDashboardData | null>(null);
  const [paddocks, setPaddocks] = useState<FeatureCollection | null>(null);
  const [showPaddocks, setShowPaddocks] = useState(true);
  const [analyzing, setAnalyzing] = useState(false);
  const [analysingArea, setAnalysingArea] = useState<Feature | null>(null);
  const [numCows, setNumCows] = useState<number | null>(null);

  const [feedlots, setFeedlots] = useState<FeatureCollection | null>(null);
  const [showFeedlots, setShowFeedlots] = useState(true);

  const [squareFeature, setSquareFeature] = useState<Feature | null>(null);

  useEffect(() => {
    console.log("Square feature updated:", squareFeature);
  }, [squareFeature]);

  function squareAroundPoint(point: Position, length: number) {
    const lengthLat = length / 110574; // Approximate length of 1 degree of latitude in meters for NSW, Australia
    const lengthLng = length / 111320; //* Math.cos(point[1] * (Math.PI / 180)); // Adjust for latitude in NSW, Australia

    return polygon([
      [
        point,
        [point[0] + lengthLng, point[1]],
        [point[0] + lengthLng, point[1] + lengthLat],
        [point[0], point[1] + lengthLat],
        point,
      ],
    ]);
  }

  function analyzeArea(point: Position | null = null) {
    setAnalyzing(true);
    setSquareFeature(null);
    console.log(analysingArea);
    console.log(numCows);

    if (!analysingArea) {
      console.log("No area to analyze");
      setAnalyzing(false);
      return;
    }

    if (!numCows) {
      console.log("No number of cows");
      setAnalyzing(false);
      return;
    }

    if (!point) {
      point = center(analysingArea).geometry.coordinates;
    }

    // 2225 m2 per 1000 cows
    const cowArea = (numCows * 2225) / 1000;
    const squareLength = Math.sqrt(cowArea);
    const square = squareAroundPoint(point, squareLength);
    setSquareFeature(square);
    setAnalyzing(false);
  }

  function updatePoint(point: Position) {
    analyzeArea(point);
  }

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      const responseData = await sessionManager.getData(
        "cattle_dashboard_data"
      );

      setData(responseData);
      setPaddocks(responseData.paddocks);
      setFeedlots(responseData.feedlots);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div style={{ display: "flex" }}>
      <div
        style={{
          width: "300px",
          padding: "10px",
          borderRight: "1px solid #ccc",
        }}
      >
        <h3>Legend</h3>
        <h4>Data Points</h4>
        <div style={{ display: "flex", flexDirection: "column" }}>
          {paddocks && (
            <div>
              <div style={{ display: "flex", alignItems: "center" }}>
                <div
                  style={{
                    width: "20px",
                    height: "20px",
                    backgroundColor: "blue",
                    marginRight: "10px",
                  }}
                ></div>
                <FormControlLabel
                  control={
                    <Switch
                      checked={showPaddocks}
                      onChange={() => setShowPaddocks(!showPaddocks)}
                      sx={{
                        "& .MuiSwitch-switchBase.Mui-checked": {
                          color: "blue",
                        },
                        "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track":
                          {
                            backgroundColor: "blue",
                          },
                      }}
                    />
                  }
                  label="Public Feedlot Farms"
                />
              </div>
            </div>
          )}
          {feedlots && (
            <div>
              <div style={{ display: "flex", alignItems: "center" }}>
                <div
                  style={{
                    width: "20px",
                    height: "20px",
                    backgroundColor: "orange",
                    marginRight: "10px",
                  }}
                ></div>
                <FormControlLabel
                  control={
                    <Switch
                      checked={showFeedlots}
                      onChange={() => setShowFeedlots(!showFeedlots)}
                      sx={{
                        "& .MuiSwitch-switchBase.Mui-checked": {
                          color: "orange",
                        },
                        "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track":
                          {
                            backgroundColor: "orange",
                          },
                      }}
                    />
                  }
                  label="Feedlot Directories"
                />
              </div>
              <Typography variant="caption">SLGA Sand Legend (2021)</Typography>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginTop: "10px",
                }}
              >
                <img
                  src="https://geoserver.tern.org.au/geoserver/slga/SND_v2/ows?service=WMS&request=GetLegendGraphic&format=image/png&layer=snd_ev_n_p_au_trn_n_20210902&version=1.1.1"
                  alt="SLGD Sand Legend"
                  style={{ marginRight: "10px" }}
                />
              </div>
            </div>
          )}
          <div style={{ marginTop: "20px" }}>
            <TextField
              label="Number of Cows"
              value={numCows}
              onChange={(e: any) => setNumCows(parseInt(e.target.value))}
            />
            <div style={{ marginTop: "10px" }}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  analyzeArea();
                }}
              >
                {analyzing ? (
                  <CircularProgress size={24} color="inherit" />
                ) : (
                  "Analyze"
                )}
              </Button>
            </div>
          </div>
        </div>
      </div>
      <div style={{ flex: 1 }}>
        <Map
          sessionManager={sessionManager}
          paddocks={showPaddocks ? paddocks : null}
          feedlots={showFeedlots ? feedlots : null}
          onPolygonClick={() => {}}
          analysingArea={analysingArea}
          setAnalysingArea={setAnalysingArea}
          square={squareFeature}
          updatePoint={updatePoint}
        />
      </div>
    </div>
  );
};

export default CattleDashboard;
