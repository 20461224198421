import {
  Container,
  Typography,
  Paper,
  Grid,
  FormControlLabel,
  Radio,
  RadioGroup,
  Box,
  List,
  ListItem,
  Checkbox,
} from '@mui/material';
import { pink } from '@mui/material/colors';
import { styled } from '@mui/material/styles';
import { SessionManager } from '../../utils/consts';
import { useState } from 'react';

interface ChecklistProps {
  sessionManager: SessionManager;
}

const HeaderBox = styled(Box)(({ theme }) => ({
  backgroundColor: pink[400],
  color: 'white',
  padding: theme.spacing(1),
  marginBottom: theme.spacing(2),
}));

const QuestionBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.grey[800],
  color: 'white',
  padding: theme.spacing(1),
  marginBottom: theme.spacing(1),
}));

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  marginBottom: theme.spacing(2),
}));

const Checklist: React.FC<ChecklistProps> = ({ sessionManager }) => {
  const [answers, setAnswers] = useState<Record<string, string>>({});
  const [checkboxes, setCheckboxes] = useState<Record<string, boolean>>({});

  const handleChange =
    (questionId: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
      setAnswers(prev => ({
        ...prev,
        [questionId]: event.target.value,
      }));
    };

  const handleCheckboxChange =
    (checkboxId: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
      setCheckboxes(prev => ({
        ...prev,
        [checkboxId]: event.target.checked,
      }));
    };

  return (
    <Container maxWidth="lg" sx={{ mt: 2 }}>
      <HeaderBox>
        <Typography variant="subtitle1">
          For APIQ compliance refer standards 4.3, 4.5
        </Typography>
        <Typography variant="subtitle1">And template SOPs 1, 3</Typography>
      </HeaderBox>

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <QuestionBox>
            <Typography variant="h6">
              6.0 Emergency Animal Disease (Ead) Management
            </Typography>
          </QuestionBox>
        </Grid>

        <Grid item xs={12}>
          <StyledPaper>
            <Grid container>
              <Grid item xs={5}>
                <Typography variant="body1">
                  6.1 Make sure everyone on your farm or site knows to call your
                  veterinarian or the Emergency Animal Disease (EAD) Watch
                  Hotline if you observe unusual signs of disease in your
                  livestock?
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <RadioGroup
                  row
                  value={answers['6.1'] || ''}
                  onChange={handleChange('6.1')}
                >
                  <FormControlLabel value="Y" control={<Radio />} label="Y" />
                  <FormControlLabel value="N" control={<Radio />} label="N" />
                  <FormControlLabel
                    value="N/A"
                    control={<Radio />}
                    label="N/A"
                  />
                </RadioGroup>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="body1">
                  The EAD hotline number is – 1800 675 888
                </Typography>
                <Typography variant="subtitle1" sx={{ mt: 2 }}>
                  I/we do this by:
                </Typography>
                <List>
                  <ListItem>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={checkboxes['6.1.1'] || false}
                          onChange={handleCheckboxChange('6.1.1')}
                        />
                      }
                      label="Staff awareness of contact details"
                    />
                  </ListItem>
                  <ListItem>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={checkboxes['6.1.2'] || false}
                          onChange={handleCheckboxChange('6.1.2')}
                        />
                      }
                      label="Written protocols or plans"
                    />
                  </ListItem>
                  <ListItem>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={checkboxes['6.1.3'] || false}
                          onChange={handleCheckboxChange('6.1.3')}
                        />
                      }
                      label="Other – describe"
                    />
                  </ListItem>
                </List>
              </Grid>
            </Grid>
          </StyledPaper>

          <StyledPaper>
            <Grid container>
              <Grid item xs={5}>
                <Typography variant="body1">
                  6.2 Display livestock emergency contacts openly for easy
                  access?
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <RadioGroup
                  row
                  value={answers['6.2'] || ''}
                  onChange={handleChange('6.2')}
                >
                  <FormControlLabel value="Y" control={<Radio />} label="Y" />
                  <FormControlLabel value="N" control={<Radio />} label="N" />
                  <FormControlLabel
                    value="N/A"
                    control={<Radio />}
                    label="N/A"
                  />
                </RadioGroup>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="subtitle1">
                  Emergency contacts for this Farm Site are displayed in the
                  following location/s:
                </Typography>
                <List sx={{ color: pink[500] }}>
                  <ListItem>• Fridge door in site office</ListItem>
                  <ListItem>• Staff entry/exit point</ListItem>
                  <ListItem>• Manager's office door</ListItem>
                  <ListItem>• Staff phone contacts</ListItem>
                </List>
              </Grid>
            </Grid>
          </StyledPaper>

          <StyledPaper>
            <Grid container>
              <Grid item xs={5}>
                <Typography variant="body1">
                  6.3 Have the capacity to hold livestock for at least 4-weeks
                  if livestock movements were not allowed?
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <RadioGroup
                  row
                  value={answers['6.3'] || ''}
                  onChange={handleChange('6.3')}
                >
                  <FormControlLabel value="Y" control={<Radio />} label="Y" />
                  <FormControlLabel value="N" control={<Radio />} label="N" />
                  <FormControlLabel
                    value="N/A"
                    control={<Radio />}
                    label="N/A"
                  />
                </RadioGroup>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="subtitle1">
                  I/we might do this by:
                </Typography>
                <List sx={{ color: pink[500] }}>
                  <ListItem>
                    • Opening up gates so livestock could spread out into space
                    in laneways
                  </ListItem>
                  <ListItem>
                    • Construct temporary fences outside sheds
                  </ListItem>
                  <ListItem>
                    • Other considerations such as double-stacking might be a
                    possibility
                  </ListItem>
                </List>
              </Grid>
            </Grid>
          </StyledPaper>

          <StyledPaper>
            <Grid container>
              <Grid item xs={5}>
                <Typography variant="body1">
                  6.4 Or your staff have the knowledge and experience to
                  humanely euthanise livestock on-site if required?
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <RadioGroup
                  row
                  value={answers['6.4'] || ''}
                  onChange={handleChange('6.4')}
                >
                  <FormControlLabel value="Y" control={<Radio />} label="Y" />
                  <FormControlLabel value="N" control={<Radio />} label="N" />
                  <FormControlLabel
                    value="N/A"
                    control={<Radio />}
                    label="N/A"
                  />
                </RadioGroup>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="subtitle1">
                  I/we might do this by:
                </Typography>
                <Typography variant="body2" sx={{ color: pink[500] }}>
                  • Some (limited) capacity with firearms and captive bolt but
                  would almost certainly need assistance if large numbers of
                  livestock were involved
                </Typography>
              </Grid>
            </Grid>
          </StyledPaper>

          <StyledPaper>
            <Grid container>
              <Grid item xs={5}>
                <Typography variant="body1">
                  6.5 Have a system for disposing of carcasses if and when
                  required?
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <RadioGroup
                  row
                  value={answers['6.5'] || ''}
                  onChange={handleChange('6.5')}
                >
                  <FormControlLabel value="Y" control={<Radio />} label="Y" />
                  <FormControlLabel value="N" control={<Radio />} label="N" />
                  <FormControlLabel
                    value="N/A"
                    control={<Radio />}
                    label="N/A"
                  />
                </RadioGroup>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="subtitle1">
                  I/we might do this by:
                </Typography>
                <List>
                  <ListItem>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={checkboxes['6.5.1'] || false}
                          onChange={handleCheckboxChange('6.5.1')}
                        />
                      }
                      label="Deep burial"
                    />
                  </ListItem>
                  <ListItem>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={checkboxes['6.5.2'] || false}
                          onChange={handleCheckboxChange('6.5.2')}
                        />
                      }
                      label="Incineration"
                    />
                  </ListItem>
                  <ListItem>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={checkboxes['6.5.3'] || false}
                          onChange={handleCheckboxChange('6.5.3')}
                        />
                      }
                      label="Composting"
                    />
                  </ListItem>
                  <ListItem>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={checkboxes['6.5.4'] || false}
                          onChange={handleCheckboxChange('6.5.4')}
                        />
                      }
                      label="Delivery to the local licensed landfill"
                    />
                  </ListItem>
                  <ListItem>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={checkboxes['6.5.5'] || false}
                          onChange={handleCheckboxChange('6.5.5')}
                        />
                      }
                      label="Other – describe"
                    />
                  </ListItem>
                </List>
              </Grid>
            </Grid>
          </StyledPaper>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Checklist;
