// src/components/Newsletter.tsx
import React, { useState } from "react";
import { Subscribe } from "./Subscribe";
import axios from "axios";
import { API_BASE_URL } from "../utils/consts";
import {
  Grid,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Typography,
  Container,
} from "@mui/material";

type NewsletterData = {
  title: string;
  description: string;
  url: string;
  urlToImage: string;
  publishedAt: string;
  content: string;
};

const Newsletter: React.FC = () => {
  const [newsletterData, setNewsletterData] = useState<NewsletterData[]>([]);

  const getData = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/news`);
      setNewsletterData(response.data);
    } catch (error) {
      console.error("Error fetching newsletter data:", error);
    }
  };

  getData();

  return (
    <Container
      maxWidth="lg"
      style={{ marginTop: "20px", marginBottom: "20px" }}
    >
      <h1>Exoflare Newsletter</h1>
      <Subscribe />
      <div style={{ marginTop: "20px" }}>
        <Grid container spacing={3} justifyContent="center">
          {newsletterData.map((item) => (
            <Grid item xs={12} sm={6} md={4} key={item.title}>
              <Card style={{ maxWidth: 345, margin: "auto" }}>
                <CardActionArea onClick={() => window.open(item.url, "_blank")}>
                  <CardMedia
                    component="img"
                    height="140"
                    image={item.urlToImage}
                    alt={item.title}
                  />
                  <CardContent>
                    <Typography variant="h5" component="div">
                      {item.title}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      {item.description}
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
          ))}
        </Grid>
      </div>
    </Container>
  );
};

export default Newsletter;
