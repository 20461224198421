import React, { useEffect, useState } from 'react';
import Profile from './Profile';
import { AppBar, IconButton, Toolbar, Button, Typography } from '@mui/material';
import {
  AccountCircle,
  Group,
  Security,
  Mail,
  Notifications,
  Settings,
  ReportProblem,
} from '@mui/icons-material';
import AgricultureIcon from '@mui/icons-material/Agriculture';
import UserManagement from './UserManagement';
import { SessionManager } from '../utils/consts';
import Dashboard from './AvianInfluenza/Dashboard';
import CattleDashboard from './Cattle/Dashboard';
import AlertsCentre from './AlertsCentre';
import { SubscriberManagement } from './SubscriberManagement';
import FarmSites from './FarmSites/FarmSites';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import SettingsIcon from '@mui/icons-material/Settings';
import HelpIcon from '@mui/icons-material/Help';
import LogoutIcon from '@mui/icons-material/Logout';

interface HomeProps {
  sessionManager: SessionManager;
}

export interface Features {
  features: string[];
}

const Home: React.FC<HomeProps> = ({ sessionManager }) => {
  const [showProfile, setShowProfile] = useState(false);
  const [showUserManagement, setShowUserManagement] = useState(false);
  const [showUserBack, setShowUserBack] = useState(false);
  const [features, setFeatures] = useState<Features | null>(null);
  const [showDashboard, setShowDashboard] = useState(false);
  const [showCattleDashboard, setShowCattleDashboard] = useState(false);
  const [showAlertsCentre, setShowAlertsCentre] = useState(false);
  const [showSubscriberManagement, setShowSubscriberManagement] =
    useState(false);
  const [showFarmSites, setShowFarmSites] = useState(false);
  const [hideSidebar, setHideSidebar] = useState(false);

  function resetAll() {
    setShowProfile(false);
    setShowUserManagement(false);
    setShowUserBack(false);
    setShowDashboard(false);
    setShowCattleDashboard(false);
    setShowAlertsCentre(false);
    setShowSubscriberManagement(false);
    setShowFarmSites(false);

    setHideSidebar(false);
    getFeatures();
  }

  const getFeatures = async () => {
    const responseData = await sessionManager.getData('features');
    setFeatures(responseData);
  };

  useEffect(() => {
    getFeatures();
    setShowFarmSites(true);
  }, []);

  return (
    <div style={{ display: 'flex' }}>
      <div
        style={{
          width: hideSidebar ? '0' : '240px',
          height: '100vh',
          backgroundColor: '#0066CC',
          padding: hideSidebar ? '0' : '20px 0',
          transition: 'all 0.3s ease',
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          display: 'flex',
          flexDirection: 'column',
          zIndex: 2000,
        }}
      >
        <div
          style={{
            padding: '0 16px',
            marginBottom: '32px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <img
            src="/exoflare_alerts.png"
            alt="ExoFlare Alerts"
            style={{ width: '80%' }}
          />
          <IconButton
            onClick={() => setHideSidebar(true)}
            style={{ color: '#FFFFFF' }}
          >
            <ChevronLeftIcon />
          </IconButton>
        </div>

        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '8px',
            flex: 1,
            overflowY: 'auto',
          }}
        >
          <Button
            startIcon={<AgricultureIcon />}
            onClick={() => {
              resetAll();
              setShowFarmSites(!showFarmSites);
              setShowUserBack(true);
            }}
            style={{
              justifyContent: 'flex-start',
              padding: '12px 16px',
              color: '#FFFFFF',
            }}
          >
            My Farm Sites
          </Button>

          {features?.features.includes('user_management') && (
            <Button
              startIcon={<Group />}
              onClick={() => {
                resetAll();
                setShowUserManagement(!showUserManagement);
                setShowUserBack(true);
              }}
              style={{
                justifyContent: 'flex-start',
                padding: '12px 16px',
                color: '#FFFFFF',
              }}
            >
              User Management
            </Button>
          )}

          {features?.features.includes('subscriber_management') && (
            <Button
              startIcon={<Group />}
              onClick={() => {
                resetAll();
                setShowSubscriberManagement(!showSubscriberManagement);
                setShowUserBack(true);
              }}
              style={{
                justifyContent: 'flex-start',
                padding: '12px 16px',
                color: '#FFFFFF',
              }}
            >
              Subscriber Management
            </Button>
          )}

          {features?.features.includes('biosecurity_dashboard') && (
            <Button
              startIcon={<Security />}
              onClick={() => {
                resetAll();
                setHideSidebar(true);
                setShowDashboard(!showDashboard);
                setShowUserBack(true);
              }}
              style={{
                justifyContent: 'flex-start',
                padding: '12px 16px',
                color: '#FFFFFF',
              }}
            >
              Avian Influenza Dashboard
            </Button>
          )}

          {features?.features.includes('biosecurity_dashboard') && (
            <Button
              startIcon={<Security />}
              onClick={() => {
                resetAll();
                setHideSidebar(true);

                setShowCattleDashboard(!showCattleDashboard);
                setShowUserBack(true);
              }}
              style={{
                justifyContent: 'flex-start',
                padding: '12px 16px',
                color: '#FFFFFF',
              }}
            >
              Feedlot Dashboard
            </Button>
          )}

          {features?.features.includes('alerts_centre') && (
            <Button
              startIcon={<Notifications />}
              onClick={() => {
                resetAll();
                setShowAlertsCentre(!showAlertsCentre);
                setShowUserBack(true);
              }}
              style={{
                justifyContent: 'flex-start',
                padding: '12px 16px',
                color: '#FFFFFF',
              }}
            >
              Alert Centre
            </Button>
          )}

          <Button
            startIcon={<Settings />}
            onClick={() => console.log('Alert Settings')}
            style={{
              justifyContent: 'flex-start',
              padding: '12px 16px',
              color: '#FFFFFF',
            }}
          >
            Alert Settings
          </Button>

          <Button
            startIcon={<Mail />}
            onClick={() => console.log('Newsletter settings')}
            style={{
              justifyContent: 'flex-start',
              padding: '12px 16px',
              color: '#FFFFFF',
            }}
          >
            Newsletter Settings
          </Button>

          <Button
            startIcon={<ReportProblem />}
            onClick={() => console.log('Report Avian Influenza')}
            style={{
              justifyContent: 'flex-start',
              padding: '12px 16px',
              color: '#FFFFFF',
            }}
          >
            Report Avian Influenza
          </Button>
        </div>

        <div
          style={{
            borderTop: '1px solid rgba(255, 255, 255, 0.1)',
            padding: '16px 0',
            marginTop: 'auto',
            display: 'flex',
            flexDirection: 'column',
            gap: '8px',
          }}
        >
          <Button
            startIcon={<SettingsIcon />}
            onClick={() => {
              resetAll();
              setShowProfile(true);
            }}
            style={{
              justifyContent: 'flex-start',
              padding: '12px 16px',
              color: '#FFFFFF',
            }}
          >
            Settings
          </Button>

          <Button
            startIcon={<HelpIcon />}
            onClick={() => console.log('Support clicked')}
            style={{
              justifyContent: 'flex-start',
              padding: '12px 16px',
              color: '#FFFFFF',
            }}
          >
            Support
          </Button>

          <Button
            startIcon={<LogoutIcon />}
            onClick={sessionManager.signOut}
            style={{
              justifyContent: 'flex-start',
              padding: '12px 16px',
              color: '#FFFFFF',
            }}
          >
            Log out
          </Button>
        </div>
      </div>

      <div style={{ flex: 1 }}>
        <AppBar position="static">
          <Toolbar>
            {hideSidebar && (
              <IconButton
                edge="start"
                color="inherit"
                onClick={() => setHideSidebar(false)}
                sx={{ mr: 2 }}
              >
                <MenuIcon />
              </IconButton>
            )}
            <div style={{ flexGrow: 1 }} />
            <IconButton
              edge="end"
              color="inherit"
              onClick={() => setShowProfile(!showProfile)}
            >
              <AccountCircle />
            </IconButton>
          </Toolbar>
        </AppBar>

        <div>
          {showProfile ? (
            <Profile sessionManager={sessionManager} />
          ) : showUserManagement ? (
            <UserManagement sessionManager={sessionManager} />
          ) : showDashboard ? (
            <Dashboard sessionManager={sessionManager} />
          ) : showAlertsCentre ? (
            <AlertsCentre sessionManager={sessionManager} />
          ) : showCattleDashboard ? (
            <CattleDashboard sessionManager={sessionManager} />
          ) : showSubscriberManagement ? (
            <SubscriberManagement sessionManager={sessionManager} />
          ) : showFarmSites ? (
            <FarmSites sessionManager={sessionManager} />
          ) : (
            <Typography variant="h4" gutterBottom style={{ marginTop: '16px' }}>
              Welcome to ExoFlare Tech
            </Typography>
          )}
        </div>
      </div>
    </div>
  );
};

export default Home;
