import React, { useState } from 'react';
import axios from 'axios';
import Signup from './components/Signup';
import Login from './components/Login';
import Home from './components/Home';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import './App.css';
import { Button, Container, Typography } from '@mui/material';
import { API_BASE_URL, SessionManager } from './utils/consts';
import { LoadScript } from '@react-google-maps/api';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom'; // Changed 'Switch' to 'Routes'
import Newsletter from './components/Newsletter';
import { Subscribe } from './components/Subscribe'; // Fixed import statement
import { ThemeProvider, createTheme } from '@mui/material/styles';

const GOOGLE_MAPS_LIBRARIES: (
  | 'places'
  | 'drawing'
  | 'geometry'
  | 'localContext'
  | 'visualization'
)[] = ['places'];

const theme = createTheme({
  palette: {
    primary: {
      main: '#0066CC',
      light: '#3384D7',
      dark: '#004C99',
    },
    background: {
      default: '#FFFFFF',
      paper: '#FFFFFF',
    },
    text: {
      primary: '#1A1A1A',
      secondary: '#666666',
    },
  },
  typography: {
    fontFamily:
      '"Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, sans-serif',
    h1: {
      fontSize: '1.5rem',
      fontWeight: 600,
    },
    button: {
      textTransform: 'none',
      fontWeight: 500,
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '4px',
          padding: '8px 16px',
          minHeight: '40px',
        },
        containedPrimary: {
          '&:hover': {
            backgroundColor: '#004C99',
          },
        },
      },
      defaultProps: {
        disableElevation: true,
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-root': {
            borderRadius: '4px',
            backgroundColor: '#FFFFFF',
            '& fieldset': {
              borderColor: '#E5E5E5',
            },
            '&:hover fieldset': {
              borderColor: '#0066CC',
            },
            '&.Mui-focused fieldset': {
              borderColor: '#0066CC',
            },
          },
        },
      },
      defaultProps: {
        size: 'small',
        fullWidth: true,
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: '#0066CC',
          boxShadow: 'none',
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.1)',
          borderRadius: '8px',
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          borderRadius: '4px',
        },
      },
    },
  },
});

function App() {
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [name, setName] = useState<string>('');
  const [phone_number, setphoneNumber] = useState<string>('');
  const [address, setAddress] = useState<string>('');
  const [token, setToken] = useState<string | null>(null);
  const [isSignup, setIsSignup] = useState<boolean>(false); // New state to toggle between forms
  const [signedTermsAndConditions, setSignedTermsAndConditions] =
    useState<boolean>(false);

  const signup = async () => {
    try {
      await axios.post(`${API_BASE_URL}/signup`, {
        email,
        password,
        phone_number: phone_number,
        name,
        address,
        signed_terms_and_conditions: signedTermsAndConditions,
      });
      setIsSignup(false); // Switch back to login after successful signup
    } catch (error) {
      alert('Signup failed!');
    }
  };

  const login = async () => {
    try {
      signOut();
      const response = await axios.post(
        `${API_BASE_URL}/token`,
        new URLSearchParams({
          username: email,
          password,
          grant_type: 'password',
        }),
        {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        }
      );
      setToken(response.data.access_token);

      // save token to local storage
    } catch (error) {
      try {
        console.log('Login failed, trying again...');
        signOut();
        const response = await axios.post(
          `${API_BASE_URL}/token`,
          new URLSearchParams({
            username: email,
            password,
            grant_type: 'password',
          }),
          {
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
            },
          }
        );
        setToken(response.data.access_token);

        // save token to local storage
      } catch (error) {
        alert('Login failed!');
      }
    }
  };

  const signOut = () => {
    setToken(null);
  };

  const getData = async (endpoint: string) => {
    if (!token) {
      console.warn('No token, signing out');
      signOut();
      return null;
    }

    try {
      const response = await axios.get(`${API_BASE_URL}/${endpoint}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 401) {
        signOut();
        return null;
      }

      if (response.status === 200) {
        return response.data;
      } else {
        throw new Error('Failed to fetch data');
      }
    } catch (error) {
      console.warn('Error fetching data, signing out');
      console.error(error);
      signOut();
      return null;
    }
  };

  const sessionManager: SessionManager = {
    getToken: () => token,
    setToken: (token: string) => setToken(token),
    signOut: signOut,
    getData: getData,
  };

  return (
    <ThemeProvider theme={theme}>
      <Router>
        <div className="App">
          <LoadScript
            googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY || ''}
            libraries={['places']}
          >
            <Routes>
              <Route path="/news" element={<Newsletter />} />
              <Route path="/subscribe" element={<Subscribe />} />
              <Route
                path="/"
                element={
                  !token ? (
                    <React.Fragment>
                      <Container
                        maxWidth="sm"
                        sx={{
                          justifyContent: 'center',
                          position: 'absolute',
                          top: '50%',
                          left: '50%',
                          transform: 'translate(-50%, -50%)',
                        }}
                      >
                        {isSignup ? (
                          <Signup
                            email={email}
                            password={password}
                            name={name}
                            phoneNumber={phone_number}
                            address={address}
                            signedTermsAndConditions={signedTermsAndConditions}
                            setEmail={setEmail}
                            setPassword={setPassword}
                            setName={setName}
                            setPhoneNumber={setphoneNumber}
                            setAddress={setAddress}
                            signup={signup}
                            setSignedTermsAndConditions={
                              setSignedTermsAndConditions
                            }
                          />
                        ) : (
                          <Login
                            email={email}
                            password={password}
                            setEmail={setEmail}
                            setPassword={setPassword}
                            login={login}
                          />
                        )}

                        <Typography
                          sx={{
                            textAlign: 'center',
                            mt: 2,
                            fontSize: '0.875rem',
                            color: 'text.secondary',
                          }}
                        >
                          {isSignup
                            ? 'Already have an account? '
                            : "Don't have an account? "}
                          <Button
                            variant="text"
                            color="primary"
                            onClick={() => setIsSignup(!isSignup)}
                            sx={{
                              p: 0,
                              minWidth: 0,
                              verticalAlign: 'baseline',
                            }}
                          >
                            {isSignup ? 'Sign In' : 'Sign Up'}
                          </Button>
                        </Typography>
                      </Container>
                    </React.Fragment>
                  ) : (
                    <Home sessionManager={sessionManager} />
                  )
                }
              />
            </Routes>
          </LoadScript>
        </div>
      </Router>
    </ThemeProvider>
  );
}

export default App;
