import axios from "axios";
import { useEffect, useState } from "react";
import { API_BASE_URL, SessionManager } from "../utils/consts";
import {
  List,
  ListItem,
  Grid,
  Paper,
  ButtonBase,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  IconButton,
  css,
} from "@mui/material";
import { LocationOn, Mail, Close } from "@mui/icons-material";

type Location = {
  address: string;
  centroid: {
    type: string;
    coordinates: [number, number];
  };
};

interface ZoneAddresses {
  restricted: Location[];
  control: Location[];
  warning: Location[];
}

const restricted_message = `One of more of your sites may now be located in an area subject to animal movement restrictions following an emergency animal disease (EAD) event.

Please call
the NSW 24-hour EAD hotline on 1800 675 888 as soon as possible, and click the link below for more information:
  https://www.dpi.nsw.gov.au/__data/assets/pdf_file/0005/1433543/EADcentral
  `;

interface AlertsCentreProps {
  sessionManager: SessionManager;
}

const AlertsCentre: React.FC<AlertsCentreProps> = ({ sessionManager }) => {
  const [zoneAddresses, setZoneAddresses] = useState<ZoneAddresses | null>(
    null
  );
  const [dialogOpen, setDialogOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState(restricted_message);
  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      const responseData = await sessionManager.getData("zone_addresses");
      setZoneAddresses(responseData);
    } catch (error) {}
  };

  const handleDialogOpen = () => {
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  return (
    <div>
      <h2>Alerts Centre</h2>
      <Grid container spacing={3}>
        <Grid item xs={4}>
          <h3>Restricted Zone</h3>
          <Button
            variant="contained"
            color="primary"
            onClick={handleDialogOpen}
          >
            <Mail />
            Send Alert
          </Button>
          <List>
            {zoneAddresses?.restricted.map((location, index) => (
              <ListItem key={`restricted-${index}`}>
                <ButtonBase style={{ width: "100%" }}>
                  <LocationOn></LocationOn>
                  <Paper
                    elevation={3}
                    style={{ padding: "10px", margin: "5px", width: "100%" }}
                  >
                    <strong>Address:</strong> {location.address}
                    <br />
                    <strong>Coordinates:</strong>{" "}
                    {location.centroid.coordinates.join(", ")}
                    <br />
                    <strong>Email:</strong> {"****@****.com"}
                    <br />
                    <strong>Phone:</strong> {"+61*********"}
                  </Paper>
                </ButtonBase>
              </ListItem>
            ))}
          </List>
        </Grid>

        <Grid item xs={4}>
          <h3>Control Zone</h3>
          <Button
            variant="contained"
            color="primary"
            onClick={handleDialogOpen}
          >
            <Mail />
            Send Alert
          </Button>
          <List>
            {zoneAddresses?.control.map((location, index) => (
              <ListItem key={`control-${index}`}>
                <ButtonBase style={{ width: "100%" }}>
                  <LocationOn></LocationOn>
                  <Paper
                    elevation={3}
                    style={{ padding: "10px", margin: "5px", width: "100%" }}
                  >
                    <strong>Address:</strong> {location.address}
                    <br />
                    <strong>Coordinates:</strong>{" "}
                    {location.centroid.coordinates.join(", ")}
                    <br />
                    <strong>Email:</strong> {"****@****.com"}
                    <br />
                    <strong>Phone:</strong> {"+61*********"}
                  </Paper>
                </ButtonBase>
              </ListItem>
            ))}
          </List>
        </Grid>

        <Grid item xs={4}>
          <h3>Warning Zone</h3>
          <Button
            variant="contained"
            color="primary"
            onClick={handleDialogOpen}
          >
            <Mail />
            Send Alert
          </Button>
          <List>
            {zoneAddresses?.warning.map((location, index) => (
              <ListItem key={`warning-${index}`}>
                <ButtonBase style={{ width: "100%" }}>
                  <LocationOn></LocationOn>
                  <Paper
                    elevation={3}
                    style={{ padding: "10px", margin: "5px", width: "100%" }}
                  >
                    <strong>Address:</strong> {location.address}
                    <br />
                    <strong>Coordinates:</strong>{" "}
                    {location.centroid.coordinates.join(", ")}
                    <br />
                    <strong>Email:</strong> {"****@****.com"}
                    <br />
                    <strong>Phone:</strong> {"+61*********"}
                  </Paper>
                </ButtonBase>
              </ListItem>
            ))}
          </List>
        </Grid>
      </Grid>

      <Dialog
        open={dialogOpen}
        onClose={handleDialogClose}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>
          Send Alert
          <IconButton
            aria-label="close"
            onClick={handleDialogClose}
            style={{ position: "absolute", right: 8, top: 8 }}
          >
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="alert-message"
            label="Alert Message"
            type="text"
            fullWidth
            multiline
            rows={10}
            variant="outlined"
            value={alertMessage}
            onChange={(e) => setAlertMessage(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary">
            Cancel
          </Button>
          <Button
            onClick={() => {
              /* handle send action */
              console.log(alertMessage);
              handleDialogClose();
            }}
            color="primary"
          >
            Send
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default AlertsCentre;
