import axios from "axios";
import Map from "./Map";
import { FeatureCollection } from "geojson";
import { useEffect, useState } from "react";
import { API_BASE_URL, SessionManager } from "../../utils/consts";
import { FormControlLabel, Switch } from "@mui/material";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";

type ZoneNumbers = {
  warning: number;
  restricted: number;
  control: number;
};

type DashboardData = {
  paddocks: FeatureCollection;
  zones: FeatureCollection;
  observations: FeatureCollection;
  water_birds: FeatureCollection;
  zone_numbers: ZoneNumbers;
};

interface DashboardProps {
  sessionManager: SessionManager;
}

const Dashboard: React.FC<DashboardProps> = ({ sessionManager }) => {
  const [data, setData] = useState<DashboardData | null>(null);
  const [paddocks, setPaddocks] = useState<FeatureCollection | null>(null);
  const [zones, setZones] = useState<FeatureCollection | null>(null);
  const [observations, setObservations] = useState<FeatureCollection | null>(
    null
  );
  const [waterBirds, setWaterBirds] = useState<FeatureCollection | null>(null);
  const [zoneNumbers, setZoneNumbers] = useState<ZoneNumbers | null>(null);
  const [showPaddocks, setShowPaddocks] = useState(true);
  const [showZones, setShowZones] = useState(true);
  const [showObservations, setShowObservations] = useState(true);
  const [showWaterBirds, setShowWaterBirds] = useState(true);
  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      const responseData = await sessionManager.getData("dashboard_data");

      setData(responseData);
      setPaddocks(responseData.paddocks);
      setZones(responseData.zones);
      setObservations(responseData.observations);
      setWaterBirds(responseData.water_birds);
      setZoneNumbers(responseData.zone_numbers);
    } catch (error) {
      console.log(error);
    }
  };

  const renderZoneNumbersChart = () => {
    if (!zoneNumbers) return null;

    const data = [
      { name: "Warning", value: zoneNumbers.warning, fill: "yellow" },
      { name: "Control", value: zoneNumbers.control, fill: "orange" },
      { name: "Restricted", value: zoneNumbers.restricted, fill: "red" },
    ];

    return (
      <BarChart
        width={300}
        height={200}
        data={data}
        margin={{
          top: 5,
          right: 30,
          left: 0,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Legend />
        <Bar dataKey="value" />
      </BarChart>
    );
  };

  return (
    <div style={{ display: "flex" }}>
      <div
        style={{
          width: "300px",
          padding: "10px",
          borderRight: "1px solid #ccc",
        }}
      >
        <h3>Legend</h3>
        <h4>Data Points</h4>
        <div style={{ display: "flex", flexDirection: "column" }}>
          {paddocks && (
            <div>
              <div style={{ display: "flex", alignItems: "center" }}>
                <div
                  style={{
                    width: "20px",
                    height: "20px",
                    backgroundColor: "blue",
                    marginRight: "10px",
                  }}
                ></div>
                <FormControlLabel
                  control={
                    <Switch
                      checked={showPaddocks}
                      onChange={() => setShowPaddocks(!showPaddocks)}
                      sx={{
                        "& .MuiSwitch-switchBase.Mui-checked": {
                          color: "blue",
                        },
                        "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track":
                          {
                            backgroundColor: "orange",
                          },
                      }}
                    />
                  }
                  label="Poultry Farms"
                />
              </div>
              <div style={{ display: "flex", alignItems: "center" }}>
                <div
                  style={{
                    width: "20px",
                    height: "20px",
                    backgroundColor: "orange",
                    marginRight: "10px",
                  }}
                ></div>
                <span>Exoflare Poultry Farms</span>
              </div>
            </div>
          )}

          <div style={{ display: "flex", alignItems: "center" }}>
            <div
              style={{
                width: "20px",
                height: "20px",
                backgroundColor: "red",
                marginRight: "10px",
              }}
            ></div>
            <FormControlLabel
              control={
                <Switch
                  checked={showObservations}
                  onChange={() => setShowObservations(!showObservations)}
                  sx={{
                    "& .MuiSwitch-switchBase.Mui-checked": {
                      color: "red",
                    },
                    "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
                      backgroundColor: "red",
                    },
                  }}
                />
              }
              label="Avian Influenza"
            />
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <div
              style={{
                width: "20px",
                height: "20px",
                backgroundColor: "green",
                marginRight: "10px",
              }}
            ></div>
            <FormControlLabel
              control={
                <Switch
                  checked={showWaterBirds}
                  onChange={() => setShowWaterBirds(!showWaterBirds)}
                  sx={{
                    "& .MuiSwitch-switchBase.Mui-checked": {
                      color: "green",
                    },
                    "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
                      backgroundColor: "green",
                    },
                  }}
                />
              }
              label="24Hr Water Bird"
            />
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <div
              style={{
                width: "20px",
                height: "20px",
                backgroundColor: "purple",
                marginRight: "10px",
              }}
            ></div>
            <span>User Address</span>
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <h4 style={{ marginRight: "10px" }}>Emergency Zones</h4>
            <Switch
              checked={showZones}
              onChange={() => setShowZones(!showZones)}
            />
          </div>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <div
                style={{
                  width: "20px",
                  height: "20px",
                  backgroundColor: "red",
                  marginRight: "10px",
                }}
              ></div>
              <span>Restricted Zone</span>
            </div>
          </div>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <div
                style={{
                  width: "20px",
                  height: "20px",
                  backgroundColor: "orange",
                  marginRight: "10px",
                }}
              ></div>
              <span>Control Zone</span>
            </div>
          </div>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <div
                style={{
                  width: "20px",
                  height: "20px",
                  backgroundColor: "yellow",
                  marginRight: "10px",
                }}
              ></div>
              <span>Warning Zone</span>
            </div>
          </div>
          {zoneNumbers && (
            <>
              <h3>Emergency Zone Poultry Farms</h3>
              {renderZoneNumbersChart()}
            </>
          )}
        </div>
      </div>
      <div style={{ flex: 1 }}>
        <Map
          sessionManager={sessionManager}
          paddocks={showPaddocks ? paddocks : null}
          zones={showZones ? zones : null}
          observations={showObservations ? observations : null}
          waterBirds={showWaterBirds ? waterBirds : null}
          onPolygonClick={() => {}}
        />
      </div>
    </div>
  );
};

export default Dashboard;
