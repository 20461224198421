import {
  MapContainer,
  TileLayer,
  GeoJSON,
  CircleMarker,
  LayersControl,
  WMSTileLayer,
  FeatureGroup, // Import FeatureGroup
} from "react-leaflet";
import { Feature, FeatureCollection, Polygon, Position } from "geojson";
import L from "leaflet";
import { useEffect, useState } from "react";
import { SessionManager } from "../../utils/consts";
import { EditControl } from "react-leaflet-draw";
import "leaflet-draw/dist/leaflet.draw.css";
import { centroid, polygon as turfPolygon } from "@turf/turf";
import { LatLng } from "leaflet";
import { useMapEvent } from "react-leaflet";

interface MapProps {
  sessionManager: SessionManager;
  paddocks: FeatureCollection | null;
  feedlots: FeatureCollection | null;
  onPolygonClick: (feature: any, event: L.LeafletMouseEvent) => void;
  analysingArea: Feature | null;
  setAnalysingArea: (area: Feature | null) => void;
  square: Feature | null;
  updatePoint: (point: Position) => void;
}

export interface ProfileProps {
  email: string;
  id: number;
  name: string;
  phone_number: string;
  address: string;
  location: string;
}

const Map: React.FC<MapProps> = ({
  sessionManager,
  paddocks,
  feedlots,
  onPolygonClick,
  analysingArea,
  setAnalysingArea,
  square,
  updatePoint,
}) => {
  const [profile, setProfile] = useState<ProfileProps | null>(null);
  const getProfile = async () => {
    const responseData = await sessionManager.getData("profile");
    setProfile(responseData);
  };

  useEffect(() => {
    getProfile();
  }, []);

  const mapClick = (e: L.LeafletMouseEvent) => {
    const position: Position = [e.latlng.lng, e.latlng.lat];
    updatePoint(position);
  };

  const MapClickHandler = () => {
    useMapEvent("click", mapClick);
    return null;
  };

  const addPolygon = (e: any) => {
    const layer = e.layer;
    const coords: LatLng[][] = layer.getLatLngs();

    // Convert Leaflet LatLngs to GeoJSON coordinates

    function toPosition(latLng: LatLng): Position {
      return [latLng.lng, latLng.lat];
    }

    const geoJsonCoords = [coords[0].map(toPosition)];
    const feature: Feature = {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: geoJsonCoords,
      },
      properties: {},
    };

    setAnalysingArea(feature);
  };

  return (
    <div
      id="map"
      style={{ height: "100vh", width: "100vw", position: "absolute" }}
    >
      <MapContainer
        center={[-33.808, 150.937]}
        zoom={11}
        style={{ height: "100%", width: "100%" }}
      >
        <MapClickHandler />
        <LayersControl position="topleft">
          <LayersControl.BaseLayer name="Map">
            <TileLayer
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            />
          </LayersControl.BaseLayer>
          <LayersControl.BaseLayer checked name="Satellite">
            <TileLayer
              url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
              attribution="Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community"
            />
          </LayersControl.BaseLayer>
          <LayersControl.Overlay name="Elevation">
            <TileLayer
              url="https://{s}.tile.opentopomap.org/{z}/{x}/{y}.png"
              attribution='Map data: &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors, <a href="http://viewfinderpanoramas.org">SRTM</a> | Map style: &copy; <a href="https://opentopomap.org">OpenTopoMap</a> (CC-BY-SA)'
              opacity={0.8}
            />
          </LayersControl.Overlay>
          <LayersControl.Overlay name="OpenLandMap Sand Fraction">
            <WMSTileLayer
              url="https://v2-geoserver.openlandmap.org/geoserver/wms/"
              layers="olm:sand_wfraction_usda_3a1a1a"
              format="image/png"
              transparent={true}
              version="1.3.0"
              crs={L.CRS.EPSG3857}
            />
          </LayersControl.Overlay>

          <LayersControl.Overlay name="SLGD Sand">
            <WMSTileLayer
              url="https://geoserver.tern.org.au/geoserver/slga/SND_v2/ows?"
              layers="snd_ev_n_p_au_trn_n_20210902"
              format="image/png"
              transparent={true}
              version="1.1.1"
              crs={L.CRS.EPSG3857}
            />
          </LayersControl.Overlay>

          <LayersControl.Overlay name="isric Soil Class">
            <WMSTileLayer
              url="https://maps.isric.org/mapserv?map=/map/wrb.map"
              layers="MostProbable"
              format="image/png"
              transparent={true}
              version="1.3.0"
              crs={L.CRS.EPSG4326}
            />
          </LayersControl.Overlay>

          <LayersControl.Overlay name="isric Sand">
            <WMSTileLayer
              url="https://maps.isric.org/mapserv?map=/map/sand.map"
              layers="sand_0-5cm_mean"
              format="image/png"
              transparent={true}
              version="1.3.0"
              crs={L.CRS.EPSG4326}
            />
          </LayersControl.Overlay>

          <FeatureGroup>
            <EditControl
              position="topleft"
              onCreated={addPolygon}
              draw={{
                rectangle: false,
                circle: false,
                circlemarker: false,
                marker: false,
                polyline: false,
              }}
            />
          </FeatureGroup>

          {square && (
            <GeoJSON
              key={JSON.stringify(square)}
              data={square}
              style={{ color: "red" }}
            />
          )}

          {paddocks && (
            <>
              <GeoJSON
                data={paddocks}
                onEachFeature={(feature, layer) => {
                  layer.on({
                    click: (event: L.LeafletMouseEvent) =>
                      onPolygonClick(feature, event),
                  });
                }}
              />
              {paddocks.features.map((feature, index) => {
                const centroid = feature.properties?.centroid.coordinates;
                const is_exoflare = feature.properties?.is_exoflare;
                if (is_exoflare) {
                  return (
                    <CircleMarker
                      key={index}
                      center={[centroid[1], centroid[0]]}
                      radius={2}
                      color="orange"
                      fillColor="orange"
                      fillOpacity={1}
                    />
                  );
                }

                return (
                  <CircleMarker
                    key={index}
                    center={[centroid[1], centroid[0]]}
                    radius={1}
                    color="blue"
                    fillColor="blue"
                    fillOpacity={1}
                  />
                );
              })}
            </>
          )}

          {feedlots && (
            <>
              {/* <GeoJSON data={feedlots} /> */}
              {feedlots.features.map((feature, index) => {
                if (feature.geometry.type === "Point") {
                  const centroid = feature.geometry.coordinates;
                  return (
                    <CircleMarker
                      key={index}
                      center={[centroid[1], centroid[0]]}
                      radius={2}
                      color="orange"
                      fillColor="orange"
                      fillOpacity={1}
                    />
                  );
                }
              })}
            </>
          )}

          {profile && profile.location && (
            <CircleMarker
              center={
                profile.location.split(",").map(Number) as [number, number]
              }
              radius={5}
              color="purple"
              fillColor="purple"
              fillOpacity={0.8}
            />
          )}
        </LayersControl>
      </MapContainer>
    </div>
  );
};

export default Map;
