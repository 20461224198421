import React, { useState } from 'react';
import {
  TextField,
  Button,
  Box,
  Typography,
  Link,
  InputAdornment,
  IconButton,
  CircularProgress,
} from '@mui/material';
import { Eye, EyeOff } from 'lucide-react';

interface LoginProps {
  email: string;
  password: string;
  setEmail: (value: string) => void;
  setPassword: (value: string) => void;
  login: () => void;
}

export default function Component({
  email,
  password,
  setEmail,
  setPassword,
  login,
}: LoginProps) {
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const handleLogin = async () => {
    setIsLoading(true);
    await login();
    setIsLoading(false);
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      handleLogin();
    }
  };

  return (
    <Box
      sx={{
        p: 3,
        border: '1px solid #E5E5E5',
        borderRadius: '8px',
      }}
    >
      <Box sx={{ textAlign: 'center', mb: 4 }}>
        <Box
          component="img"
          src="/exoflare_tech.png"
          alt="ExoFlare Logo"
          sx={{
            height: '60px',
            width: 'auto',
            display: 'block',
            margin: 'auto',
            marginBottom: '20px',
            marginTop: '40px',
          }}
        />
      </Box>

      <Typography
        variant="h6"
        sx={{ mb: 3, textAlign: 'center', fontWeight: 'bold' }}
      >
        Login to your account
      </Typography>

      <Box
        component="form"
        onKeyDown={handleKeyDown}
        sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 0.5,
            alignItems: 'flex-start',
          }}
        >
          <Typography
            component="label"
            htmlFor="email"
            sx={{ fontSize: '0.875rem', fontWeight: 'bold' }}
          >
            <Box component="span" sx={{ color: 'error.main' }}>
              *
            </Box>{' '}
            Email
          </Typography>
          <TextField
            id="email"
            value={email}
            onChange={e => setEmail(e.target.value)}
            placeholder="Enter mobile phone or email"
            fullWidth
            size="small"
          />
        </Box>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 0.5,
            alignItems: 'flex-start',
          }}
        >
          <Typography
            component="label"
            htmlFor="password"
            sx={{ fontSize: '0.875rem', fontWeight: 'bold' }}
          >
            <Box component="span" sx={{ color: 'error.main' }}>
              *
            </Box>{' '}
            Password
          </Typography>
          <TextField
            id="password"
            type={showPassword ? 'text' : 'password'}
            value={password}
            onChange={e => setPassword(e.target.value)}
            fullWidth
            size="small"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setShowPassword(!showPassword)}
                    edge="end"
                    size="small"
                  >
                    {showPassword ? <Eye size={16} /> : <EyeOff size={16} />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Box>

        <Link
          href="#"
          sx={{
            color: 'primary.main',
            textDecoration: 'underline',
            fontSize: '0.875rem',
            fontWeight: 'bold',
            alignSelf: 'flex-start',
          }}
        >
          Forgot password?
        </Link>

        <Button
          variant="contained"
          onClick={handleLogin}
          disabled={isLoading}
          sx={{
            mt: 1,
            py: 1.5,
            textTransform: 'uppercase',
            fontWeight: 'bold',
            position: 'relative',
          }}
        >
          {isLoading ? (
            <CircularProgress
              size={24}
              sx={{
                color: 'white',
                position: 'absolute',
                top: '50%',
                left: '50%',
                marginTop: '-12px',
                marginLeft: '-12px',
              }}
            />
          ) : (
            'Login'
          )}
        </Button>
      </Box>
    </Box>
  );
}
