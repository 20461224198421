import React, { useEffect, useState } from "react";
import { Container, Typography, Paper, Grid, Button } from "@mui/material";
import axios from "axios";
import { API_BASE_URL, SessionManager } from "../utils/consts";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";

interface User {
  id: number;
  name: string;
  email: string;
  phone_number: string;
  address: string;
  user_type: string;
}

interface Users {
  users: User[];
}

interface UserManagementProps {
  sessionManager: SessionManager;
}

const UserManagement: React.FC<UserManagementProps> = ({ sessionManager }) => {
  const [users, setUsers] = useState<Users>({ users: [] });
  const [userToUpdate, setUserToUpdate] = useState<User | null>(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedUserType, setSelectedUserType] = useState<string>("default");

  const getUsers = async () => {
    const responseData = await sessionManager.getData("all_users");
    setUsers(responseData);
  };

  useEffect(() => {
    getUsers();
  }, []);

  function updateUser(user: User) {
    setUserToUpdate(user);
  }

  const handleOpenDialog = (user: User) => {
    setUserToUpdate(user);
    setSelectedUserType(user.user_type);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setUserToUpdate(null);
  };

  const handleUserTypeChange = (event: SelectChangeEvent) => {
    setSelectedUserType(event.target.value as string);
  };

  const handleSaveUserType = async () => {
    if (userToUpdate) {
      try {
        const token = sessionManager.getToken();
        await axios.post(
          `${API_BASE_URL}/update_user_type`,
          {
            user_id: userToUpdate.id,
            user_type: selectedUserType,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        console.log("updating...");
        console.log(selectedUserType);
        console.log(userToUpdate);
        getUsers(); // Refresh the user list
        handleCloseDialog();
      } catch (error) {
        if (axios.isAxiosError(error)) {
          console.error(
            "Error updating user type:",
            error.response?.data || error.message
          );
          sessionManager.signOut();
        } else {
          console.error("Unexpected error:", error);
        }
      }
    }
  };

  return (
    <Container>
      <Typography variant="h4" gutterBottom>
        User Management
      </Typography>
      <Grid container spacing={3}>
        {users.users.map((user) => (
          <Grid item xs={12} sm={6} md={4} key={user.id}>
            <Paper elevation={3} style={{ padding: "16px" }}>
              <Typography variant="h6">{user.name}</Typography>
              <Typography variant="body1">Email: {user.email}</Typography>
              <Typography variant="body1">
                Phone: {user.phone_number}
              </Typography>
              <Typography variant="body1">Address: {user.address}</Typography>
              <Typography variant="body1">Type: {user.user_type}</Typography>
              <Button onClick={() => handleOpenDialog(user)}>Update</Button>
            </Paper>
          </Grid>
        ))}
      </Grid>
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>Update User Type</DialogTitle>
        <DialogContent>
          <Select
            value={selectedUserType}
            onChange={handleUserTypeChange}
            fullWidth
          >
            <MenuItem value="admin">Admin</MenuItem>
            <MenuItem value="power">Power</MenuItem>
            <MenuItem value="default">Default</MenuItem>
          </Select>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSaveUserType} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default UserManagement;
