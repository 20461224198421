import React from "react";
import ReactDOM from "react-dom/client"; // Update import
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "@fontsource/roboto"; // Import Roboto font
import { CssBaseline } from "@mui/material"; // Optional: Normalize styles

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
); // Create root
root.render(
  <React.StrictMode>
    <CssBaseline /> {/* Optional: Normalize styles */}
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
