import React, { useEffect, useState, useRef } from 'react';
import {
  Container,
  Typography,
  AppBar,
  Toolbar,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import SaveIcon from '@mui/icons-material/Save';
import { API_BASE_URL, SessionManager } from '../../utils/consts';
import axios from 'axios';

import {
  MapContainer,
  TileLayer,
  GeoJSON,
  CircleMarker,
  LayersControl,
  WMSTileLayer,
  FeatureGroup,
  useMapEvent, // Import FeatureGroup
} from 'react-leaflet';
import { Feature, FeatureCollection, Polygon, Position } from 'geojson';
import L, { LatLng, map } from 'leaflet';
import { EditControl } from 'react-leaflet-draw';

type FarmFeatureType = {
  typeName: string;
  typeColor: string;
  featureType: string;
};

const farmFeatureTypes: FarmFeatureType[] = [
  { typeName: 'Field', typeColor: '#00FF00', featureType: 'Polygon' },
  { typeName: 'Building', typeColor: '#0000FF', featureType: 'Polygon' },
  { typeName: 'Road', typeColor: '#FF0000', featureType: 'LineString' },
  { typeName: 'Gate', typeColor: '#FFFF00', featureType: 'Point' },
  { typeName: 'Dam', typeColor: '#00FFFF', featureType: 'Polygon' },
];

export interface Site {
  id: number;
  name: string;
  address: string;
  geoJson: Feature;
  center: [number, number];
}

interface UpdateSiteData {
  site_id: number;
  site_features_geojson: FeatureCollection;
}

interface SiteManagerProps {
  sessionManager: SessionManager;
  site: Site;
  exitAndRefresh: () => void;
}

const toPosition = (latLng: L.LatLng): Position => {
  return [latLng.lng, latLng.lat];
};

const SiteManager: React.FC<SiteManagerProps> = ({
  sessionManager,
  site,
  exitAndRefresh,
}) => {
  const [openDialog, setOpenDialog] = useState(false);
  const [featureName, setFeatureName] = useState('');
  const [tempFeature, setTempFeature] = useState<Feature | null>(null);
  const [siteFeatureName, setSiteFeatureName] = useState<string>('');
  const [initialFeatures, setInitialFeatures] =
    useState<FeatureCollection | null>(null);

  const boundaryFeature: Feature = {
    type: 'Feature',
    geometry: site.geoJson.geometry,
    properties: {
      typeName: 'Boundary',
    },
  };
  const featureCollection: FeatureCollection = {
    type: 'FeatureCollection',
    features: [boundaryFeature],
  };

  const [siteFeaturesGeojson, setSiteFeaturesGeojson] =
    useState<FeatureCollection>(featureCollection);

  const getSiteData = async () => {
    const token = sessionManager.getToken();
    const site_id = site.id;
    const response = await axios.get(`${API_BASE_URL}/get_site`, {
      headers: { Authorization: `Bearer ${token}` },
      params: { site_id },
    });
    const data: { site_features_geojson: string | undefined } = response.data;
    if (data.site_features_geojson) {
      const geojson = JSON.parse(data.site_features_geojson);
      setInitialFeatures(geojson);
      setSiteFeaturesGeojson(geojson);
    }
  };

  useEffect(() => {
    getSiteData();
  }, []);

  function updateSiteFeaturesGeojson(feature: Feature) {
    const newFeatureCollection = {
      ...siteFeaturesGeojson,
      features: [...siteFeaturesGeojson.features, feature],
    };
    setSiteFeaturesGeojson(newFeatureCollection);
  }

  const addFeature = (e: any) => {
    const layer = e.layer;
    let feature: Feature | undefined;

    console.log(typeof layer);

    if (layer instanceof L.Polygon) {
      // make red
      layer.setStyle({ color: '#FF0000' });
      const latlngs = layer.getLatLngs() as L.LatLng[];
      console.log(latlngs);
      const geoJsonCoords = [[...latlngs.map(toPosition)]];
      feature = {
        type: 'Feature',
        geometry: {
          type: 'Polygon',
          coordinates: geoJsonCoords,
        },
        properties: {},
      };
    } else if (layer instanceof L.Polyline) {
      const coords: LatLng[] = layer.getLatLngs() as LatLng[];
      feature = {
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: coords.map(toPosition),
        },
        properties: {},
      };
    } else if (layer instanceof L.Marker) {
      const pos = layer.getLatLng() as L.LatLng;
      feature = {
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: toPosition(pos),
        },
        properties: {},
      };
    }

    if (feature) {
      setTempFeature(feature);
      setOpenDialog(true);
    }
  };

  const editControlRef = useRef<any>(null);

  const AddArea = () => {
    const polygonButton = document.querySelector('.leaflet-draw-draw-polygon');
    if (polygonButton instanceof HTMLElement) {
      polygonButton.click();
    }
    setSiteFeatureName('Area');
  };

  const addRoad = () => {
    const polylineButton = document.querySelector(
      '.leaflet-draw-draw-polyline'
    );
    if (polylineButton instanceof HTMLElement) {
      polylineButton.click();
    }
    setSiteFeatureName('Road');
  };

  const addGate = () => {
    const markerButton = document.querySelector('.leaflet-draw-draw-marker');
    if (markerButton instanceof HTMLElement) {
      markerButton.click();
    }
    setSiteFeatureName('Gate');
  };

  const saveFeature = () => {
    if (tempFeature && featureName) {
      console.log('Feature Name:', featureName);
      if (tempFeature.properties) {
        tempFeature.properties.name = featureName;
        tempFeature.properties.typeName = siteFeatureName;
      } else {
        tempFeature.properties = { name: featureName };
      }
      updateSiteFeaturesGeojson(tempFeature);

      setOpenDialog(false);
      setFeatureName('');
      setTempFeature(null);
      console.log(siteFeaturesGeojson);
    }
  };

  const saveAndExit = async () => {
    const updateData: UpdateSiteData = {
      site_id: site.id,
      site_features_geojson: siteFeaturesGeojson,
    };
    console.log(updateData);
    try {
      const token = sessionManager.getToken();
      await axios.post(
        `${API_BASE_URL}/update_site_features`,
        {
          site_id: updateData.site_id,
          site_features_geojson: updateData.site_features_geojson,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
    } catch (error) {
      console.error('Error creating site:', error);
    }
    exitAndRefresh();
  };

  return (
    <div>
      <div
        id="map"
        style={{ height: '100%', width: '100%', position: 'absolute' }}
      >
        <MapContainer
          center={[site.center[1], site.center[0]]}
          zoom={15}
          style={{ height: '100%', width: '100%' }}
        >
          <LayersControl position="topleft">
            <LayersControl.BaseLayer name="Map">
              <TileLayer
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              />
            </LayersControl.BaseLayer>
            <LayersControl.BaseLayer checked name="Satellite">
              <TileLayer
                url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
                attribution="Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community"
              />
            </LayersControl.BaseLayer>

            <FeatureGroup>
              <EditControl
                ref={editControlRef}
                position="topleft"
                onCreated={addFeature}
                draw={{
                  rectangle: false,
                  circle: false,
                  circlemarker: false,
                  polygon: true,
                  polyline: true,
                  marker: true,
                }}
              />
            </FeatureGroup>

            {initialFeatures && (
              <GeoJSON
                data={initialFeatures}
                style={{ fillOpacity: 0.2, weight: 4 }}
              />
            )}

            <GeoJSON
              data={site.geoJson}
              style={{ fillOpacity: 0, weight: 4 }}
            />
          </LayersControl>
        </MapContainer>
      </div>

      <AppBar
        position="fixed"
        color="primary"
        sx={{
          top: 'auto',
          bottom: 0,
        }}
        style={{ width: '100%' }}
      >
        <Toolbar style={{ width: '100%', left: '240px' }}>
          <Container
            maxWidth="md"
            sx={{ display: 'flex', justifyContent: 'flex-end' }}
          >
            <Button
              variant="contained"
              sx={{
                backgroundColor: 'white',
                color: 'black',
                '&:hover': {
                  backgroundColor: '#f5f5f5',
                },
              }}
              startIcon={<AddIcon />}
              style={{ marginRight: '10px' }}
              onClick={() => {
                AddArea();
              }}
            >
              Add Area
            </Button>

            <Button
              variant="contained"
              sx={{ backgroundColor: 'white', color: 'black' }}
              onClick={addRoad}
              startIcon={<AddIcon />}
              style={{ marginRight: '10px' }}
            >
              Add Road
            </Button>
            <Button
              variant="contained"
              sx={{ backgroundColor: 'white', color: 'black' }}
              onClick={addGate}
              startIcon={<AddIcon />}
              style={{ marginRight: '10px' }}
            >
              Add Gate
            </Button>
            <Button
              variant="contained"
              sx={{ backgroundColor: 'white', color: 'black' }}
              onClick={saveAndExit}
              startIcon={<SaveIcon />}
              style={{ marginRight: '10px' }}
            >
              Save and Exit
            </Button>
          </Container>
        </Toolbar>
      </AppBar>

      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogTitle>Add Feature Details</DialogTitle>

        <DialogContent>
          <Typography variant="subtitle1" sx={{ mb: 2, fontWeight: 500 }}>
            Site Feature: <strong>{siteFeatureName}</strong>
          </Typography>
          <TextField
            autoFocus
            margin="dense"
            label="Feature Name"
            fullWidth
            variant="outlined"
            value={featureName}
            onChange={e => setFeatureName(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)}>Cancel</Button>
          <Button onClick={saveFeature} variant="contained" color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default SiteManager;
