// src/components/Subscribe.tsx
import React, { useState } from "react";
import { TextField, Button, Box } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import { getNames, getCode } from "country-list";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert, { AlertProps } from "@mui/material/Alert";
import axios from "axios";
import { API_BASE_URL } from "../utils/consts";

const countries = getNames().map((name) => ({
  label: name,
  code: getCode(name),
}));

const industries = [
  "Grains",
  "Horticulture",
  "Dairy",
  "Beef",
  "Sheep",
  "Poultry",
  "Fisheries",
  "Forestry",
  "Viticulture", // Wine production
  "Aquaculture", // Fish farming
  "Apiary", // Beekeeping
  "Nursery", // Plant nurseries
];

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export const Subscribe: React.FC = () => {
  const [email, setEmail] = useState<string>("");
  const [country, setCountry] = useState<string | null>(null);
  const [industry, setIndustry] = useState<string | null>(null);
  const [open, setOpen] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [subscribed, setSubscribed] = useState<boolean>(false);

  const handleSubscribe = () => {
    if (email && country && industry) {
      // check if email is valid
      if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
        setError("Please enter a valid email address.");
        return;
      }
      // check if country is valid
      if (!countries.some((c) => c.label === country)) {
        setError("Please select a valid country.");
        return;
      }
      // check if industry is valid
      if (!industries.includes(industry)) {
        setError("Please select a valid industry.");
        return;
      }
      // post to subscribe endpoint
      axios
        .post(`${API_BASE_URL}/subscribe`, {
          email,
          country,
          industry,
        })
        .then((response) => {
          if (response.status === 200) {
            setSubscribed(true);
          } else {
            setError("Something went wrong. Please try again.");
          }
        });
    } else {
      setError("Please fill in all fields.");
    }
  };

  const handleClose = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
    setError(null);
  };
  return (
    // thank you for subscribing
    <div>
      {subscribed ? (
        <Box sx={{ maxWidth: 400, margin: "auto", textAlign: "center", mt: 4 }}>
          <h2>Thank you for subscribing!</h2>
        </Box>
      ) : (
        <Box sx={{ maxWidth: 400, margin: "auto", textAlign: "center", mt: 4 }}>
          <h2>Subscribe to our Newsletter</h2>
          <TextField
            label="Email"
            variant="outlined"
            fullWidth
            margin="normal"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <Autocomplete
            options={countries}
            getOptionLabel={(option) => option.label}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Country"
                variant="outlined"
                margin="normal"
              />
            )}
            onChange={(event, value) => setCountry(value ? value.label : null)}
            fullWidth
          />
          <Autocomplete
            options={industries}
            getOptionLabel={(option) => option}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Industry"
                variant="outlined"
                margin="normal"
                fullWidth
              />
            )}
            onChange={(event, value) => setIndustry(value ? value : null)}
          />
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubscribe}
            sx={{ mt: 2 }}
          >
            Subscribe
          </Button>
          <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
            <Alert onClose={handleClose} severity="success">
              Subscription successful!
            </Alert>
          </Snackbar>
          <Snackbar
            open={!!error}
            autoHideDuration={6000}
            onClose={handleClose}
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          >
            <Alert onClose={handleClose} severity="error">
              {error}
            </Alert>
          </Snackbar>
        </Box>
      )}
    </div>
  );
};
